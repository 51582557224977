<template>
  <div class="bidsDesc w">
    <div class="bids_desc_top_big_box">
      <div class="bids_desc_top_info">
        <div class="name_and_price_box">
          <div class="bids_desc_name">{{detailsData.name}}</div>
          <div class="jobs_pirce" v-if="detailsData.calculate_method!=1">
            ￥{{ detailsData.salary }}/
            <template v-if="detailsData.calculate_method==2">天</template>
            <template v-if="detailsData.calculate_method==3">月</template>
          </div>
          <div class="jobs_pirce" v-if="detailsData.calculate_method==1">
            <span>面议</span>
          </div>
        </div>
        <div class="bids_area_box">
          <div class="bids_ep_row_box">
            <div class="bids_ep_row_title">所招岗位：</div>
            <div class="bids_ep_row_text">{{detailsData.category_name}}</div>
          </div>
          <div class="bids_ep_row_box">
            <div class="bids_ep_row_title">地区：</div>
            <div class="bids_ep_row_text">{{ detailsData.province + detailsData.city + detailsData.address }}</div>
          </div>
          <div class="bids_ep_row_box">
            <div class="bids_ep_row_title">发布时间：</div>
            <div class="bids_ep_row_text">{{detailsData.create_time}}</div>
          </div>
        </div>
      </div>
      <div class="top_right_btn_box">
        <div class="phone_or_send">
          <button class="q_blue_btn" @click="sendResume">投简历</button>
          <button class="s_blue_btn" @click="goLink('/personalCenter/member')" v-if="!$store.state.userInfo.vip.vip_level">开通会员查看电话</button>
          <button class="s_blue_btn" @click="callPhone"
            v-if="$store.state.userInfo.vip.vip_level&&$store.state.userInfo.vip.vip_level!=0">电话联系</button>
        </div>
        <div class="collect_btn" @click="collectFunc">
          <img src="../../assets/img/icon/icon_active_collect.png" alt="" v-if="detailsData.if_collect">
          <img src="../../assets/img/icon/icon_collect.png" alt="" v-else>
          <span>收藏</span>
        </div>
      </div>
    </div>

    <div class="desc_content">
      <!-- 摘要盒子 -->
      <div class="area_box">
        <div class="area-title">招工企业</div>
        <div class="company_box">
          <div class="company_info_box">
            <div class="company_img_box">
              <img :src="detailsData.image" alt="">
            </div>
            <div class="company_name">
              {{ detailsData.company_name}}
            </div>
          </div>
          <div class="check_company_btn">
            <button class="s_blue_btn" @click="goEpDeatils">查看企业</button>
          </div>
        </div>
      </div>
      <!-- 详情盒子 -->
      <div class="area_box bids_details_box">
        <div class="area-title">详情</div>
        <div class="inner_desc_box" v-if="detailsData.desc">
          <p v-html="formattedString"></p>
        </div>
        <div class="inner_desc_box" v-else>
          ****************************************************************************
        </div>
        <bigOpenVipVue v-if="auth!=='pass'"></bigOpenVipVue>
      </div>
    </div>
  </div>
</template>

<script>
import bigOpenVipVue from '../../components/vipPopup/bigOpenVip.vue';
import smallOpenVip from '../../components/vipPopup/smallOpenVip.vue';
export default {
  components: {
    smallOpenVip,
    bigOpenVipVue
  },
  data () {
    return {
      vipShow: false,
      approveShow: false,
      id: '',
      auth: '',
      detailsData: {}
    };
  },
  created () {
    this.id = this.$route.query.id;
    this.getDetails();
  },
  methods: {
    closeVipShow () {
      this.vipShow = false;
    },
    closeApproveShow () {
      this.approveShow = false;
    },
    sendResume () {
      this.$api.sendResume({ id: this.id * 1, company_id: this.detailsData.company_id }).then(res => {
        if (res.code == 1) {
          this.$message.success('投递成功!');
        }
      });
    },
    collectFunc () {
      this.$api.collect({ id: this.id, type: 'job' }).then(res => {
        if (res.code == 1) {
          if (res.data.if_collect == 0) {
            this.$message.success('已取消收藏');
          } else if (res.data.if_collect == 1) {
            this.$message.success('收藏成功!');
          }
          this.detailsData.if_collect = res.data.if_collect;
        }
      });
    },
    getDetails () {
      this.$api.getJobDetail({ id: this.id }).then(res => {
        this.detailsData = res.data;
        this.auth = 'pass';
      });
    },
    //前往企业详情页
    goEpDeatils () {
      this.$router.push({
        path: '/ep-details',
        query: {
          id: this.detailsData.company_id,
        }
      })
    },
    callPhone () {
      alert(`
         联系方式:${this.detailsData.contact_phone}
          `)
    }
  },
  computed: {
    formattedString () {
      // 使用正则表达式将换行符替换为<br>标签
      return this.detailsData.desc.replace(/\r\n/g, "<br>");
    },
  },
};
</script>

<style lang="scss">
.bidsDesc {
  min-height: 100vh;
  background-color: #f4f6fa;
  box-sizing: border-box;
  padding: 31px 0px;
  .bids_desc_top_big_box {
    background: #ffffff;
    border-radius: 8px;
    padding: 25px 19px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .bids_desc_top_info {
      .name_and_price_box {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .jobs_pirce {
          font-size: 24px;
          font-weight: 500;
          color: #ff5100;
        }
        .bids_desc_name {
          font-size: 28px;
          font-weight: 500;
          color: #333333;
          margin-right: 20px;
        }
      }

      .bids_area_box {
        display: flex;
        align-items: center;
        gap: 10px 30px;
        flex-wrap: wrap;
        .bids_ep_row_box {
          display: flex;
          align-items: center;
          font-size: 14px;
          .bids_ep_row_title {
            color: #999999;
          }
          .bids_ep_row_text {
            color: #333333;
          }
        }
      }
    }
    .top_right_btn_box {
      flex: none;
      .phone_or_send {
        display: flex;
        align-items: center;
        gap: 0 20px;
        margin-bottom: 12px;
      }
      .collect_btn {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        width: fit-content;
        float: right;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
          margin-right: 3px;
        }
      }
    }
  }

  .desc_content {
    .area_box {
      position: relative;
    }
    .area-title {
      font-size: 18px;
      margin-bottom: 20px;
    }
    .company_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .company_info_box {
        display: flex;
        align-items: center;
        .company_img_box {
          width: 58px;
          height: 58px;
          background: #ffffff;
          border-radius: 50%;
          overflow: hidden;
          opacity: 1;
          border: 1px solid #f3f3f3;
          margin-right: 15px;
        }
        .company_name {
          font-size: 18px;
          color: #333;
        }
      }
      .check_company_btn {
        .s_blue_btn {
          padding: 8px 21px;
        }
      }
    }
    .bids_details_box {
      min-height: 612px;
    }
    .desc_tabs {
      margin-bottom: 40px;
    }
    .row_list_box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;
    }
    .inner_desc_box {
      font-size: 15px;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
    }
    .files_list_box {
      .files_item {
        width: 100%;
        height: 700px;
      }
    }
  }

  //认证提醒
  .approve_box {
    width: 596px;
    box-sizing: border-box;
    background: linear-gradient(180deg, #cee3fc 0%, #ffffff 40%, #ffffff 100%);
    border-radius: 20px;
    opacity: 1;
    padding: 40px 48px;
    text-align: center;
    color: #333333;
    .approve_title {
      font-size: 36px;
      font-weight: 500;
      margin-bottom: 52px;
    }
    .approve_text {
      font-size: 30px;
      margin-bottom: 88px;
    }
    .approve_btn_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .cancel_btn,
      .comfirm_btn {
        width: 240px;
        height: 68px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        background: #f6f6f6;
        border-radius: 10px;
        border: 2px solid #cecece;
        color: #333;
      }
      .comfirm_btn {
        background: #3f63ff;
        color: #ffffff;
      }
    }
  }
}
</style>
